import React from 'react'
import elfPhoto from '../images/elf.JPG'

const Elf = () => {
    return (
        <div className="elf">
            <div className="elf-letter">
                Dear Savannah,<br/>
                We've been hearing about you! Us elves communicate with each other and always know if you're being good! Make sure you listen to mom and dad, brush your hair, and follow directions so Santa gets good news about your behavior. We miss you!<br/>
                Love, Trickster and Bubblegum
            </div>
            <div className="elf-photo">
                <img src={elfPhoto} alt="elf at computer"/>
            </div>
        </div>
    )
}

export default Elf
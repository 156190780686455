import confetti from 'canvas-confetti';
// import React, { useEffect } from 'react'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';

import './App.css';
// import BalloonsConfetti from './components/Balloons'
// import BirthdayBanner from './components/BirthdayBanner'
import Elf from './components/Elf';


function App() {

  // snow
  var duration = 15 * 1000;
  var animationEnd = Date.now() + duration;
  var skew = 1;

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  (function frame() {
    var timeLeft = animationEnd - Date.now();
    var ticks = Math.max(200, 500 * (timeLeft / duration));
    skew = Math.max(0.8, skew - 0.001);

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: ticks,
      origin: {
        x: Math.random(),
        // since particles fall down, skew start toward the top
        y: (Math.random() * skew) - 0.2
      },
      colors: ['#ffffff'],
      shapes: ['circle'],
      gravity: randomInRange(0.4, 0.6),
      scalar: randomInRange(0.4, 1),
      drift: randomInRange(-0.4, 0.4)
    });

    if (timeLeft > 0) {
      requestAnimationFrame(frame);
    }
  }());

  // let getConfetti = () => {
  //   confetti({
  //     particleCount: 200,
  //     angle: 60,
  //     spread: 55,
  //     origin: { x: 0 }
  //   })
  //   confetti({
  //     particleCount: 200,
  //     angle: 120,
  //     spread: 55,
  //     origin: { x: 1 }
  //   });
  // }

  // useEffect(() => {
  //   getConfetti()
  // }, [])

  return (
    <div className="App">
      {/* {lightsAndSnow()} */}
      <div className="light">
        <ul className="line">
            <li className="red"></li>
            <li className="yellow"></li>
            <li className="blue"></li>
            <li className="pink"></li>
            <li className="red"></li>
            <li className="green"></li>
            <li className="blue"></li>
            <li className="yellow"></li>
            <li className="red"></li>
            <li className="pink"></li>
            <li className="blue"></li>
            <li className="yellow"></li>
            <li className="red"></li>
            <li className="green"></li>
            <li className="blue"></li>
            <li className="yellow"></li>
            <li className="red"></li>
            <li className="pink"></li>
            <li className="green"></li>
            <li className="blue"></li>
            <li className="pink"></li>
            <li className="red"></li>
            <li className="green"></li>
            <li className="blue"></li>
        </ul>
    </div>

      <Elf/>
      {/* <BirthdayBanner/>
      <BalloonsConfetti/>
      <div class="button-container">
        <div class="confetti-btn">
          <Button variant="outline-light" onClick={getConfetti}>CELEBRATE!</Button>
        </div>
      </div>
      <div class="footer">created by: <a href="https://github.com/carriemathieu">carrie mathieu</a> - December 2021 </div> */}
    </div>
  );
}

export default App;
